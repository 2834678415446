html {
    font-size: 1px;
}

body {
    margin: 0;
    color: rgba(0, 0, 0, .88);
    background-color: rgba(250, 250, 250, 1);
    font-size: 14rem;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Table reorder */
:where(.css-dev-only-do-not-override-mzwlov).ant-table-wrapper .ant-table.ant-table-small .ant-table-cell, :where(.css-dev-only-do-not-override-mzwlov).ant-table-wrapper .ant-table.ant-table-small .ant-table-thead>tr>th,
:where(.css-dev-only-do-not-override-mzwlov).ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-content >table >tbody>tr>td {
    padding: 6px 8px;
}
