.app {
    padding: 30rem;
    width: 1280rem;
    min-height: 100vh;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 20rem;
}

.app .spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}